<div class="card" [class]="tableClass">
  <p-table [value]="tableData">
    <ng-template pTemplate="header">
      <tr>
        <th *ngFor="let header of headers">{{ header.title }}</th>
        <th *ngIf="actionItems"></th>
      </tr>
    </ng-template>
    <ng-template pTemplate="body" let-data>
      <ng-container *ngIf="!isGroupedTable; else ngCodeTableTemplate">
        <tr>
          <td *ngFor="let header of headers">
            <span
              *ngIf="header.key === 'isActive'; else defaultContent"
              [ngClass]="{
                'status-active': data[header.key] === true,
                'status-inactive': data[header.key] === false
              }"
            >
              {{ data[header.key] === true ? "Active" : "Inactive" }}
            </span>
            <ng-template #defaultContent>
              <ng-container *ngIf="header.key === 'role'; else dynamicContent">
                <app-sort-by
                  *ngIf="showUserType"
                  [sortOptions]="roleOptions"
                  [dropdownClass]="'border-radius-10'"
                  [selectedId]="data.roleId"
                  (onSortChange)="onRoleChange($event, data)"
                  [isFilter]="false"
                  [dropdownClass]="
                    data.roleId === 1
                      ? 'role-user user-management-role'
                      : 'user-management-role'
                  "
                >
                </app-sort-by>
                {{ data[header.key] }}
              </ng-container>
              <ng-template #dynamicContent>
                <span
                  *ngIf="header.key === 'createdDate'; else updatedDateContent"
                >
                  {{ data[header.key] ? (data[header.key] | date) : "-" }}
                </span>
                <ng-template #updatedDateContent>
                  <span
                    *ngIf="
                      header.key === 'updatedAt';
                      else typeOfIncidentContent
                    "
                  >
                    {{
                      data[header.key]
                        ? (data[header.key] | date : "dd/MM/yyyy, HH:mm:ss")
                        : "-"
                    }}
                  </span>
                  <ng-template #typeOfIncidentContent>
                    <span
                      *ngIf="header.key === 'typeOfIncident'; else plantName"
                      [ngClass]="{
                        'bg-red': data[header.key] === 0,
                        'bg-yellow': data[header.key] === 1,
                        'bg-green': data[header.key] === 2
                      }"
                    >
                      {{
                        data[header.key] === 0
                          ? "Accident"
                          : data[header.key] === 1
                          ? "First Aid"
                          : "Near Miss"
                      }}
                    </span>
                    <ng-template #plantName>
                      <span
                        *ngIf="header.key === 'plantName'; else textContent"
                        (click)="clickOnPlantName(data)"
                        class="actions-button"
                      >
                        {{
                          data[header.key]
                            ? data[header.key]
                            : data[header.key] == 0
                            ? "0"
                            : "-"
                        }}
                      </span>
                      <ng-template #textContent>{{
                        data[header.key]
                          ? data[header.key]
                          : data[header.key] == 0
                          ? "0"
                          : "-"
                      }}</ng-template>
                    </ng-template>
                  </ng-template>
                </ng-template>
              </ng-template>
            </ng-template>
          </td>
          <td *ngIf="actionItems">
            <button
              class="actions-button"
              (click)="onActionsClick($event, data)"
            >
              <i class="pi pi-ellipsis-h"></i>
            </button>
          </td>
        </tr>
      </ng-container>
      <ng-template #ngCodeTableTemplate>
        <ng-container *ngIf="isEquipmentDownTime; else showNgCodes">
          <ng-container
            *ngFor="let detail of data.alarmCodeData; let i = index"
          >
            <tr>
              <td *ngIf="i === 0" [attr.rowspan]="data.alarmCodeData.length">
                {{
                  data.occurenceDateTime
                    ? (data.occurenceDateTime | date : "dd/MM/yyyy")
                    : "-"
                }}
              </td>

              <td *ngIf="i === 0" [attr.rowspan]="data.alarmCodeData.length">
                {{ data.machineName }}
              </td>
              <td>{{ detail.alarmCode }}</td>
              <td>
                {{ detail.alarmDescription ? detail.alarmDescription : "-" }}
              </td>
            </tr>
          </ng-container>
        </ng-container>
        <ng-template #showNgCodes>
          <ng-container *ngFor="let detail of data.ngCodes; let i = index">
            <tr>
              <td *ngIf="i === 0" [attr.rowspan]="data.ngCodes.length">
                {{ data.machineDescription }}
              </td>
              <td *ngIf="i === 0" [attr.rowspan]="data.ngCodes.length">
                {{ data.machineCode }}
              </td>
              <td *ngIf="i === 0" [attr.rowspan]="data.ngCodes.length">
                {{ data.moduleNo }}
              </td>
              <td>{{ detail.name }}</td>
              <td>{{ detail.description ? detail.description : "-" }}</td>
              <td>
                <span
                  [ngClass]="{
                    'status-active': detail.isActive === true,
                    'status-inactive': detail.isActive === false
                  }"
                >
                  {{ detail.isActive === true ? "Active" : "Inactive" }}
                </span>
              </td>
              <td *ngIf="actionItems">
                <button
                  class="actions-button"
                  (click)="onActionsClick($event, data, detail)"
                >
                  <i class="pi pi-ellipsis-h"></i>
                </button>
              </td>
            </tr>
          </ng-container>
        </ng-template>
      </ng-template>
    </ng-template>
    <ng-template pTemplate="emptymessage">
      <tr>
        <td [attr.colspan]="headers.length + 2" class="text-center">
          No Data found!
        </td>
      </tr>
    </ng-template>
    <ng-template pTemplate="footer">
      <tr class="custom-footer" *ngIf="tableData?.length">
        <td [attr.colspan]="headers.length + (actionItems ? 1 : 0)">
          <div *ngIf="isPagination" class="paginator-container">
            <div class="paginator-left">
              Page
              <p-dropdown
                [options]="pageOptions"
                [(ngModel)]="currentPage"
                (onChange)="onPageChange($event)"
                [style]="{ width: '60px' }"
                appendTo="body"
                class="page-dropdown"
                [panelStyle]="{ position: 'absolute', bottom: '100%' }"
              >
              </p-dropdown>
              of {{ totalPages }}
            </div>
            <div class="paginator-right">
              <button
                pButton
                class="pi pi-arrow-left"
                (click)="prevPage()"
                [disabled]="currentPage === 1"
              ></button>
              <button
                pButton
                class="pi pi-arrow-right"
                (click)="nextPage()"
                [disabled]="currentPage === totalPages || totalPages === 0"
              ></button>
            </div>
          </div>
        </td>
      </tr>
    </ng-template>
  </p-table>
  <p-contextMenu #contextMenu [model]="contextMenu.model"></p-contextMenu>
</div>
